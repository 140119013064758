import React from "react"

export default function Coworker() {
  return (
    <section id="coworkez">
      <div className="container">
        <h2 className="coworkez__title">COWORKEZ SIMPLEMENT</h2>
        <div className="coworkez__blocks">
          <div className="coworkez__blocks__elm">
            <img
              src="./img/icon/search-01.png"
              alt=""
              className="coworkez__blocks__elm__icon"
            />
            <h4 className="coworkez__blocks__elm__title">Choisissez</h4>
            <p className="coworkez__blocks__elm__description">
              Effectuez une recherche et trouvez l'endroit qui correspond à vos
              besoins et à votre budget.
            </p>
          </div>
          <div className="coworkez__blocks__elm">
            <img
              src="./img/icon/online-booking.png"
              alt=""
              className="coworkez__blocks__elm__icon"
            />
            <h4 className="coworkez__blocks__elm__title">Reservez </h4>
            <p className="coworkez__blocks__elm__description">
              Reservez un espace et convenez des conditions qui répondent à vos
              besoins particuliers.
            </p>
          </div>
          <div className="coworkez__blocks__elm">
            <img
              src="./img/icon/Group_10.png"
              alt=""
              className="coworkez__blocks__elm__icon"
            />
            <h4 className="coworkez__blocks__elm__title">CoWorkerisez</h4>
            <p className="coworkez__blocks__elm__description">
              Une fois le propriétaire donne son accord et vous effectuez le
              paiement, vous êtes prêt à utiliser l'espace.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
