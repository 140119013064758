import React from "react";

export default function WhichOne() {
  return (
    <section id="which-one">
      <div className="container">
        <h2 className="which-one__title">QUEL CHERCHEUR D'ESPACE êtes-VOUS?</h2>
        <div className="which-one__blocks">
          <div className="which-one__blocks__elm">
            <img
              src="/img/icon/ETUDIANT_ok.png"
              alt=""
              className="which-one__blocks__elm__icon"
            />
            <h4 className="which-one__blocks__elm__title">étudiant</h4>
            <p className="which-one__blocks__elm__description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur rerum magni harum iusto fuga adipisci!
            </p>
          </div>

          <div className="which-one__blocks__elm">
            <img
              src="/img/icon/entre_ok.png"
              alt=""
              className="which-one__blocks__elm__icon"
            />
            <h4 className="which-one__blocks__elm__title">freelancer</h4>
            <p className="which-one__blocks__elm__description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur rerum magni harum iusto fuga adipisci!
            </p>
          </div>
          <div className="which-one__blocks__elm">
            <img
              src="/img/icon/salaries_ok.png"
              alt=""
              className="which-one__blocks__elm__icon"
            />
            <h4 className="which-one__blocks__elm__title">enterprise</h4>
            <p className="which-one__blocks__elm__description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur rerum magni harum iusto fuga adipisci!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
