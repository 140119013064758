import React from "react";
import Hero from "../components/Hero";
import WhichOne from "../components/WhichOne";
import Coworker from "../components/Coworker";
import ContactForm from "../components/contact/ContactForm";
import Footer from "../components/layout/Footer";
import SpaceHome from "../components/SpaceHome";
export default function Home() {
  const heroContent = {
    title: "ET SI ON RAPPROCHAIT DOMICILE ET BUREAU ?",
    paragraph:
      "Nomade est un service de bureaux partagés et d’espaces de co-working proposé par La minstere de la culture Algérienne.",
    bgImage:
      "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1526&q=80",
    cta: {
      ctaTitle: "JE VEUX EN SAVOIR PLUS",
      ctaLink: "",
    },
  };
  return (
    <React.Fragment>
      <Hero data={heroContent} />
      <SpaceHome />
      <WhichOne />
      <Coworker />
      <ContactForm />
      <Footer />
    </React.Fragment>
  );
}
