import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export default function SpaceHome() {
  const data = useStaticQuery(graphql`
    {
      allContentfulTheOfficeSpace(limit: 3) {
        nodes {
          slug
          spaceName
          spaceCoverPicture {
            file {
              url
            }
          }
        }
      }
    }
  `);
  return (
    <div className="container">
      <section id="nomade-list">
        <h2>Trouver un espace THEOFFICE</h2>
        <div className="nomade-warper">
          {data.allContentfulTheOfficeSpace.nodes.map(space => (
            <div className="nomade-space" key={space.slug}>
              <div className="nomade-space__warper">
                <img
                  src={space.spaceCoverPicture.file.url}
                  alt=""
                  className="nomade-space__img"
                />
                <h3 className="nomade-space__title">{space.spaceName}</h3>
              </div>
              <div className="nomade-space__btns">
                <Link
                  className="nomade-space__btn nomade-space__btn__discover"
                  to={space.slug}
                >
                  Découvrir
                </Link>
                <a
                  href=""
                  className="nomade-space__btn nomade-space__btn__book"
                >
                  Réserver
                </a>
              </div>
            </div>
          ))}
          <a href="" className="see-all__space">
            Voir toutes les espaces
          </a>
        </div>
      </section>
    </div>
  );
}
